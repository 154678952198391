import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AmortizationSchedule from '../components/amortization-schedule'
import ChunkingSchedule from '../components/chunking-schedule'
import EquityAndInterestGraph from '../components/equity-and-interest-graph'

const VelocityBankingWithYouhodlerPage: React.FC = () => (
  <Layout>
    <SEO title="Velocity Banking With Cryptocurrency on Youhodler" />
    <h1>Velocity Banking With Cryptocurrency on Youhodler</h1>

    <p>
      Velocity banking is a debt payment acceleration strategy using your
      existing cashflow to efficiently pay off your home loan in a shorter
      period of time. Aggressively using this strategy can allow you to pay off
      a standard 30 year mortgage in 5-7 years.
    </p>

    <p>
      If you already HODL cryptocurrency there are some unique twists on
      velocity banking at your disposal. There are now several providers of
      loans within the cryptocurrency ecosystem. You can use your cryptocurrency
      as collatoral to take out a fiat loan, chunk your amortized home loan and
      use your regular cashflow to pay off your cryptocurrency loan.
    </p>

    <div>
      <AmortizationSchedule />
      <ChunkingSchedule />
      <EquityAndInterestGraph />
    </div>
  </Layout>
)

export default VelocityBankingWithYouhodlerPage
